<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-row v-if="helpdeskData != null">
        <b-col cols="12">
          <b-card>
            <div class="clientTitle mb-1">
              {{ helpdeskData.project_name }} - {{ helpdeskData.client_name }}
            </div>

            <span
              @click="activeTab = 1"
              :class="{
                activeMenu: activeTab === 1,
                passiveMenu: activeTab !== 1,
              }"
            >
              <i class="fa-solid fa-table-columns mr-25 "></i> Overview
            </span>
            <span
              @click="activeTab = 2"
              :class="{
                activeMenu: activeTab === 2,
                passiveMenu: activeTab !== 2,
              }"
              v-if="$Can('client_projects_tab')"
              v-show="false"
            >
              <i class="fa-solid fa-border-all mr-25 " d></i> Knowledge Base
            </span>
            <span
              @click="activeTab = 3"
              :class="{
                activeMenu: activeTab === 3,
                passiveMenu: activeTab !== 3,
              }"
              v-if="$Can('client_reports_tab')"
            >
              <i class="fa-solid fa-chart-line mr-25"></i> Files
            </span>
            <span
              @click="activeTab = 4"
              :class="{
                activeMenu: activeTab === 4,
                passiveMenu: activeTab !== 4,
              }"
              v-if="$Can('client_retainer_tab')"
            >
              <i class="fa-solid fa-comment-dots mr-25 "></i> Faq
            </span>

            <hr />

            <div v-if="activeTab == 1">
              <b-table
                striped
                hover
                ref="refUserListTable"
                head-variant="dark"
                class="position-relative"
                :items="helpdeskData.users"
                selectable
                select-mode="single"
                responsive
                :small="false"
                :fields="tableColumns"
                show-empty
                empty-text="No matching records found"
              >
                <!-- Column: Id -->
                <template #cell(id)="data">
                  <strong class="text-primary">#{{ data.value }}</strong>
                </template>

                <!-- Column: Name -->
                <template #cell(name)="data">
                  {{ data.value }}
                </template>

                <!-- Column: Name -->
                <template #cell(surname)="data">
                  {{ data.value }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <div class="text-center">
                    <feather-icon icon="XSquareIcon" size="24" class=" " />
                  </div>
                </template>
              </b-table>
            </div>

            <div v-if="activeTab == 2">
              <projects-component></projects-component>
            </div>

            <div v-if="activeTab == 3">
              <!-- <reports-component :client-clockify-id="helpdeskData.project_id" :client-name="helpdeskData.name"></reports-component> -->
            </div>

            <div v-if="activeTab == 4">
              <faq-component
                :helpdesk-project-id="helpdeskData.project_id"
              ></faq-component>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import clientStoreModule from "./helpdeskStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import { BFormDatepicker } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "quill/dist/quill.snow.css";

import ProjectsComponent from "./ProjectsComponent.vue";

import ReportsComponent from "./ReportsComponent.vue";
import FaqComponent from "./FaqComponent.vue";

export default {
  components: {
    ToastificationContent,
    quillEditor,
    vSelect,
    BFormDatepicker,
    ProjectsComponent,
    ReportsComponent,
    FaqComponent,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const HELPDESK_APP_STORE_MODULE_NAME = "helpdesk";

    // Register module
    if (!store.hasModule(HELPDESK_APP_STORE_MODULE_NAME))
      store.registerModule(HELPDESK_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HELPDESK_APP_STORE_MODULE_NAME))
        store.unregisterModule(HELPDESK_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const descriptionOption = {
      modules: {
        toolbar: "#description-toolbar",
      },
      placeholder: "What is this task about?",
    };

    return {
      selectedUserId: null,
      selectedUserTitle: null,

      selectedContactId: null,
      selectedContactTitle: null,

      tempMemberRole: null,
      tempContactRole: null,
      users: [],
      contacts: [],
      editedDescription: null,
      descriptionOption,

      isNameEditing: false,
      isDescriptionEditing: false,
      loading: true,
      activeTab: 1,

      helpdeskData: {},
      initialData: {},

      tableColumns: [
        { key: "id", sortable: true, class: "text-center" },
        { key: "name", sortable: true, class: "text-center" },
        { key: "surname", sortable: true, class: "text-center" },
        { key: "email", sortable: true, class: "text-center" },
        { key: "actions", sortable: false, class: "text-center" },
      ],
    };
  },

  mounted() {
    this.helpdeskById();
  },
  watch: {
    activeTab(val) {
      localStorage.setItem("activeClientTab", val);
    },
  },

  methods: {
    handleDescriptionClick(helpdeskData) {
      if (this.$Can("client_edit_btn")) {
        this.isDescriptionEditing = true;
        this.editedDescription = helpdeskData.description;
      }
    },

    handleNameClick(helpdeskData) {
      if (this.$Can("client_edit_btn")) {
        this.isNameEditing = true;
      }
    },

    onInputFocus(fieldName) {
      this.initialData[fieldName] = this.helpdeskData[fieldName];
    },
    onInputBlur(fieldName) {
      if (fieldName === "email") {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(this.helpdeskData.email)) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              text: "Please enter a valid email address.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          return;
        }
      }

      if (this.helpdeskData[fieldName] !== this.initialData[fieldName]) {
        this.updateClient();
      }
    },

    helpdeskById() {
      this.loading = true;
      const _id = parseInt(router.currentRoute.params.id);

      store
        .dispatch("helpdesk/helpdeskById", { id: _id })
        .then((res) => {
          this.helpdeskData = res.data;

          const savedActiveTab = localStorage.getItem("activeClientTab");
          this.activeTab = savedActiveTab ? parseInt(savedActiveTab, 10) : 1;

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    updateClientName() {
      this.loading = true;
      this.isNameEditing = false;
      store
        .dispatch("helpdesk/updateClient", this.helpdeskData)
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Successful",
              text: "✔️ Client Name has been updated",
              icon: "ThumbsUpIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    updateClientNameEnter() {
      this.isNameEditing = false;
    },

    updateClientDescription() {
      this.loading = true;
      this.helpdeskData.description = this.editedDescription;
      store
        .dispatch("helpdesk/updateClient", this.helpdeskData)
        .then((res) => {
          this.loading = false;
          this.isDescriptionEditing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Successful",
              text: "✔️ Client Description has been updated",
              icon: "ThumbsUpIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    cancelDescriptionEdit() {
      this.isDescriptionEditing = false;
      this.editedDescription = this.helpdeskData.description;
    },

    updateClient() {
      this.loading = true;
      store
        .dispatch("helpdesk/updateClient", this.helpdeskData)
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Successful",
              text: "✔️ Client has been updated",
              icon: "ThumbsUpIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
span.activeMenu {
  font-weight: 700;
  color: black;
  padding-bottom: 2px;
  box-shadow: inset 0 -2px #6d6e6f;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.passiveMenu {
  font-weight: 400;
  color: #6d6e6f;
  padding-bottom: 2px;
  transition: padding 0.3s;
  padding: 5px 10px 5px;
  margin-right: 10px;
}

span.activeMenu:hover {
  background-color: #f9f8f8;
  padding: 5px 10px 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

span.passiveMenu:hover {
  background-color: #f9f8f8;
  box-shadow: inset 0 -2px #afabac;
  padding: 5px 10px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
  transition: padding 0.3s;
}

.clientTitle {
  font-size: 1.75rem;
  font-weight: 400;
  transition: padding 0.3s;
  border: solid 1px transparent;
  border-radius: 5px;
  cursor: pointer;
  text-transform: capitalize;
}

.subTitle {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}

.person {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px transparent;
  border-radius: 5px;
  transition: border 0.3s;
  height: 110px;
  max-width: 300px;
  min-width: 200px;
  background-color: #fff;
  margin-right: 10px;
  margin-top: 10px;
}

.person:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  border-color: #ccc;
}

.description {
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 1px transparent;
  transition: border 0.3s;
  border-radius: 5px;

  // background-color: #00000003;
}

.description:hover {
  background-color: #f9f8f8;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: #ccc;
}

.name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    border-top-right-radius: 0rem;
    border-top-left-radius: 0rem;
    min-height: 50px;
  }

  + #description-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.cardClass {
  padding: 1.25rem;
}
</style>
