<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <div class="d-flex justify-content-between">
      <div class="text-left mb-1">
        <b-button
          variant="primary"
          @click="selectGroupId = null"
          v-if="selectGroupId > 0"
          >Back</b-button
        >
      </div>

      <div class="text-right mb-1" v-if="selectGroupId == null">
        <b-button variant="primary" @click="openGroupModal()"
          >Add Group Title</b-button
        >
      </div>

      <div class="text-right mb-1" v-else>
        <b-button variant="primary" @click="openQuestionModal()"
          >Add Question</b-button
        >
      </div>
    </div>

    <b-modal
      id="modal-create-faq"
      @ok="saveTitle"
      @show="resetModal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :ok-only="true"
      cancel-variant="outline-secondary"
      header-bg-variant="primary"
      size="sm"
      ok-title="Save"
      centered
      title="Create new client"
    >
      <validation-observer ref="faqRules">
        <b-form>
          <b-form-group>
            <label for="title">Group Title</label>
            <validation-provider
              name="Group Title"
              #default="{ errors }"
              rules="required"
            >
              <b-form-input
                id="title"
                type="text"
                v-model="title"
                placeholder="Add new title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-create-question"
      @ok="saveQuestion"
      @show="resetModal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :ok-only="true"
      cancel-variant="outline-secondary"
      header-bg-variant="primary"
      size="sm"
      ok-title="Save"
      centered
      title="Create new question"
    >
      <validation-observer ref="questionRules">
        <b-form>
          <b-form-group>
            <label for="question">Question</label>
            <validation-provider
              name="Question"
              #default="{ errors }"
              rules="required"
            >
              <b-form-input
                id="question"
                type="text"
                v-model="question"
                placeholder="Add new question"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="answer">Answer</label>
            <validation-provider
              name="Group Answer"
              #default="{ errors }"
              rules="required"
            >
              <b-form-input
                id="answer"
                type="text"
                v-model="answer"
                placeholder="Add new answer"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="answer">Video Link</label>
            <validation-provider
              name="videoLink"
              #default="{ errors }"
              rules="url"
            >
              <b-form-input
                id="videoLink"
                type="text"
                v-model="videoLink"
                placeholder="Add Video Link"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- groups title -->
    <div class="row" v-if="selectGroupId == null">
      <div
        class="col-md-4 mb-3"
        style="cursor:pointer"
        @click="selectedGroup(faq, index)"
        v-for="(faq, index) in faqDatas"
        :key="index"
      >
        <div
          class="faq-box p-3 border d-flex align-items-center justify-content-between"
        >
          <feather-icon :icon="faq.icon" size="24" class="mr-3" />
          <p class="mb-0">{{ faq.title }}</p>
        </div>
      </div>
    </div>
    <!-- questions -->
    <app-collapse accordion type="margin" v-if="selectGroupId > 0">
      <app-collapse-item
        :title="question.title"
        v-for="question in selectedQuestions"
        :key="question.id"
      >
        <hr />
        <div class="pl-1">
          <p>{{ question.answer }}</p>
          <a :href="question.videoLink" target="_blank">
            <b-badge variant="secondary" class="mr-1">{{
              question.videoLink
            }}</b-badge>
          </a>
        </div>
      </app-collapse-item>
    </app-collapse>
  </b-overlay>
</template>

<script>
import store from "@/store";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { required, email } from "@validations";
import router from "@/router";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Treeselect from "@riophae/vue-treeselect";
import { BBadge } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    vSelect,
    ToastificationContent,
    Treeselect,
    AppCollapseItem,
    AppCollapse,
    flatPickr,
    BBadge,
    ValidationProvider,
    ValidationObserver,
  },

  computed: {},

  created() {
    this.getFaqTitles();
  },

  data() {
    return {
      loading: false,
      faqDatas: [],
      selectedQuestions: [],
      title: "",
      question: "",
      answer: "",
      videoLink: "",
      icon: "Cancellation & Return",
      selectGroupId: null,
    };
  },

  props: {
    helpdeskProjectId: Number,
  },

  mounted() {},

  methods: {
    getFaqTitles() {
      this.loading = true;
      const _id = parseInt(router.currentRoute.params.id);

      store
        .dispatch("helpdesk/faqGroupList", { id: _id })
        .then((res) => {
          this.faqDatas = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    resetModal() {
      this.title = "";
      this.question = "";
      this.answer = "";
      this.videoLink = "";
    },

    openGroupModal() {
      this.$bvModal.show("modal-create-faq");
    },

    openQuestionModal() {
      this.$bvModal.show("modal-create-question");
    },

    saveTitle(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$refs.faqRules.validate().then((success) => {
        if (success) {
          this.loading = true;
          store
            .dispatch("helpdesk/saveFaqTitle", {
              title: this.title,
              projectId: this.helpdeskProjectId,
            })
            .then((res) => {
              this.getFaqTitles();
              this.$bvModal.hide("modal-create-faq");
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successful",
                  text: "✔️ Title has been created",
                  icon: "ThumbsUpIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "An error occurred",
                  text: "Please try again later or contact support.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },

    saveQuestion(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$refs.questionRules.validate().then((success) => {
        if (success) {
          this.loading = true;
          store
            .dispatch("helpdesk/saveFaqQuestion", {
              question: this.question,
              answer: this.answer,
              faq_id: this.selectGroupId,
              videoLink: this.videoLink ?? null,
            })
            .then((res) => {
              this.selectedQuestions = res.data.questions ?? [];
              this.$bvModal.hide("modal-create-question");
              this.loading = false;
              this.resetModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successful",
                  text: "✔️ Question has been created",
                  icon: "ThumbsUpIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "An error occurred",
                  text: "Please try again later or contact support.",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },

    selectedGroup(faq, index) {
      this.selectGroupId = faq.id;

      this.selectedQuestions = faq.questions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.user-badge {
  width: 50%;
  align-content: center;
}

.no-result-message {
  font-size: 24px;
  color: #d8d6de;
  text-align: center;
  margin-top: 20px;
}

.strikethrough {
  text-decoration: line-through;
}
.faq-box {
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
