var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","no-fade":""}},[(_vm.helpdeskData != null)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('div',{staticClass:"clientTitle mb-1"},[_vm._v(" "+_vm._s(_vm.helpdeskData.project_name)+" - "+_vm._s(_vm.helpdeskData.client_name)+" ")]),_c('span',{class:{
              activeMenu: _vm.activeTab === 1,
              passiveMenu: _vm.activeTab !== 1,
            },on:{"click":function($event){_vm.activeTab = 1}}},[_c('i',{staticClass:"fa-solid fa-table-columns mr-25 "}),_vm._v(" Overview ")]),(_vm.$Can('client_projects_tab'))?_c('span',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],class:{
              activeMenu: _vm.activeTab === 2,
              passiveMenu: _vm.activeTab !== 2,
            },on:{"click":function($event){_vm.activeTab = 2}}},[_c('i',{staticClass:"fa-solid fa-border-all mr-25 ",attrs:{"d":""}}),_vm._v(" Knowledge Base ")]):_vm._e(),(_vm.$Can('client_reports_tab'))?_c('span',{class:{
              activeMenu: _vm.activeTab === 3,
              passiveMenu: _vm.activeTab !== 3,
            },on:{"click":function($event){_vm.activeTab = 3}}},[_c('i',{staticClass:"fa-solid fa-chart-line mr-25"}),_vm._v(" Files ")]):_vm._e(),(_vm.$Can('client_retainer_tab'))?_c('span',{class:{
              activeMenu: _vm.activeTab === 4,
              passiveMenu: _vm.activeTab !== 4,
            },on:{"click":function($event){_vm.activeTab = 4}}},[_c('i',{staticClass:"fa-solid fa-comment-dots mr-25 "}),_vm._v(" Faq ")]):_vm._e(),_c('hr'),(_vm.activeTab == 1)?_c('div',[_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"striped":"","hover":"","head-variant":"dark","items":_vm.helpdeskData.users,"selectable":"","select-mode":"single","responsive":"","small":false,"fields":_vm.tableColumns,"show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('strong',{staticClass:"text-primary"},[_vm._v("#"+_vm._s(data.value))])]}},{key:"cell(name)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(surname)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('feather-icon',{staticClass:" ",attrs:{"icon":"XSquareIcon","size":"24"}})],1)]}}],null,false,1433757093)})],1):_vm._e(),(_vm.activeTab == 2)?_c('div',[_c('projects-component')],1):_vm._e(),(_vm.activeTab == 3)?_c('div'):_vm._e(),(_vm.activeTab == 4)?_c('div',[_c('faq-component',{attrs:{"helpdesk-project-id":_vm.helpdeskData.project_id}})],1):_vm._e()])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }